import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import { createBrowserHistory } from 'history';
import { Permission, SystemTypeByPermission } from './constants';
import LoadingBackdrop from './components/LoadingBackdrop';
import { useSelector } from 'react-redux';
import { AppState } from './redux/rootReducer';

// Containers
const BackEndLayout = React.lazy(
  () => import('./containers/BackEnd/BackEndLayout')
);
const PersonalLayout = React.lazy(
  () => import('./containers/Personal/PersonalLayout')
);
const ParentLayout = React.lazy(
  () => import('./containers/Parent/ParentLayout')
);
const GroupLayout = React.lazy(() => import('./containers/Group/GroupLayout'));
const CoachLayout = React.lazy(() => import('./containers/Coach/CoachLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const Entry = React.lazy(() => import('./views/Pages/Entry/Entry'));

export const history = createBrowserHistory();

function App() {
  const openLoadingBackdrop = useSelector(
    (state: AppState) => state.loadingBackdrop.loading
  );

  // const api_regex = /^\/api\/.*/;
  // if (api_regex.test(window.location.pathname)) return <div>404</div>;

  return (
    <Router history={history}>
      <LoadingBackdrop open={openLoadingBackdrop} />
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path='/login' name='Login Page' component={Login} />
          <Route
            exact
            path='/register'
            name='Register Page'
            component={Register}
          />
          <Route exact path='/404' name='Does Not Exist' component={Page404} />
          <Route
            exact
            path='/connection-error'
            name='Connection Error'
            component={Page500}
          />
          <PrivateRoute
            exact
            path='/entry'
            permissions={[...Object.values(Permission)]}
            component={Entry}
          />
          <PrivateRoute
            path={'/' + SystemTypeByPermission[Permission.Personal]}
            permissions={[Permission.Personal]}
            render={(props) => <PersonalLayout {...props} />}
          />
          <PrivateRoute
            path={'/' + SystemTypeByPermission[Permission.ParentContact]}
            permissions={[Permission.ParentContact]}
            render={(props) => <ParentLayout {...props} />}
          />
          <PrivateRoute
            path={'/' + SystemTypeByPermission[Permission.GroupContact]}
            permissions={[Permission.GroupContact]}
            render={(props) => <GroupLayout {...props} />}
          />
          <PrivateRoute
            path={'/' + SystemTypeByPermission[Permission.Coach]}
            permissions={[Permission.Coach]}
            render={(props) => <CoachLayout {...props} />}
          />
          <PrivateRoute
            path={'/' + SystemTypeByPermission[Permission.Admin]}
            permissions={[
              Permission.Admin,
              Permission.BackEndFull,
              Permission.BackEndFinance,
            ]}
            render={(props) => <BackEndLayout {...props} />}
          />
          <Route exact path='/' render={() => <Redirect to='/entry' />} />
          <Route
            path='/api'
            component={() => {
              return null;
            }}
          />
          <Route component={Page404} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
